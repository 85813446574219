<template>
  <Panel :isActive="isActive">
    <PanelTitle
      :data="line"
      :length="lineLength()"
      @close="cancel">
    </PanelTitle>

    <PanelContent>
      <Button
        v-if="line && !line.editable"
        class="is-fullwidth is-light"
        :title="$t('general.edit')"
        icon="icon-edit"
        @click="edit">
      </Button>

      <form v-if="line && line.editable" class="form">
        <FormField :label="$t('general.name')">
          <TextInputField
            v-model="line.label"
            :disabled="isLoading"
            :placeholder="$t('general.name')">
          </TextInputField>
        </FormField>

        <FormField :label="$t('general.thickness')">
          <RangeSlider
            v-model="width"
            :disabled="isLoading"
            min="1"
            max="10"
            step="1"
            value="0"
            :label="width">
          </RangeSlider>
        </FormField>

        <FormField :label="$t('general.color')">
          <Alert v-if="isIE" type="info" :message="$t('map.colorPickerNotSupportedMessage')" />

          <ColorInputField
            v-else
            v-model="color"
            :disabled="isLoading"
            :placeholder="$t('general.color')">
          </ColorInputField>
        </FormField>

        <FormField v-if="line.id && createdBy !== null" :control="false">
          <small>{{ $t('map.general.createdAt', { date: createdAt, name: createdBy.getFullName() }) }}</small>
        </FormField>

        <ButtonGroup class="push-top-xl">
          <Button
            type="primary"
            :loading="isSaving"
            :disabled="isRemoving"
            :title="$t('general.save')"
            @click="update">
          </Button>

          <Button
            :disabled="isLoading"
            :title="$t('general.cancel')"
            @click="cancel">
          </Button>

          <Button
            v-if="line.id"
            type="danger"
            :loading="isRemoving"
            :disabled="isSaving"
            icon="icon-trash"
            @click="confirmRemove">
          </Button>
        </ButtonGroup>
      </form>
    </PanelContent>
  </Panel>
</template>

<script>
/* global google */
import { eventManager } from '@/main'
import RangeSlider from '@/components/ui/rangeSlider/'
import LineModel from '@/api/map/lines/lineModel'

const STATE_DEFAULT = ''
const STATE_SAVING = 'saving'
const STATE_REMOVING = 'removing'

export default {
  components: {
    RangeSlider
  },

  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      lineId: this.$route.params.lineId,
      state: STATE_DEFAULT,
      original: null,
      createdBy: null
    }
  },

  computed: {
    line () {
      return this.$store.getters['map/lines/selected']
    },

    isActive () {
      return this.line !== undefined
    },

    editable: {
      get () { return this.line ? this.line.editable : false },
      set (state) { this.line.editable = state }
    },

    color: {
      get () { return this.editable ? this.line.color : '#000000' },
      set (color) { this.line.color = color }
    },

    width: {
      get () { return this.line ? this.line.width : 3 },
      set (width) { this.line.width = width }
    },

    isLoading () {
      return this.isSaving || this.isRemoving
    },

    isSaving () {
      return this.state === STATE_SAVING
    },

    isRemoving () {
      return this.state === STATE_REMOVING
    },

    createdAt () {
      return this.line && this.$dayjs(this.line.createdAt).format('D MMMM YYYY, HH:mm')
    },

    isIE () {
      return this.$utils.isInternetExplorer()
    }
  },

  methods: {
    close () {
      this.$store.dispatch('map/lines/select', null)
      this.$router.push(`/area/${this.huntAreaId}/map`)
    },

    async edit () {
      this.original = new LineModel(this.line)
      this.line.editable = true

      if (this.line.createdBy) {
        // This code must be executed last to prevent any delays in the UI.
        // On failure we just fail silently and don't show the label.
        this.createdBy = await this.$store.dispatch('user/read', this.line.createdBy)
      }
    },

    cancel () {
      if (!this.line) return

      if (this.editable) {
        this.line.label = this.original.label
        this.line.path = this.original.path
        this.line.width = this.original.width
        this.line.color = this.original.color
        this.line.editable = false

        this.state = STATE_DEFAULT
        this.original = null
      }

      this.close()
    },

    async update () {
      this.state = STATE_SAVING

      try {
        await this.$store.dispatch('map/lines/update', {
          huntAreaId: this.huntAreaId,
          line: this.line
        })

        this.line.editable = false
        this.close()
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      } finally {
        this.state = STATE_DEFAULT
      }
    },

    async confirmRemove () {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('general.remove'),
          message: this.$t('general.reallyRemove', { name: this.line.label }),
          cancel: this.$t('general.cancel'),
          ok: this.$t('general.remove')
        })

        if (response.ok) {
          await this.remove()
          this.cancel()
        }
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      }
    },

    async remove () {
      this.state = STATE_REMOVING

      try {
        await this.$store.dispatch('map/lines/delete', {
          huntAreaId: this.huntAreaId,
          line: this.line
        })

        if (this.line) {
          this.line.visible = false
        }

        eventManager.$emit('boundary:fitBounds')
      } finally {
        this.state = STATE_DEFAULT
      }
    },

    changeWidth (e) {
      this.line.width = e.target.value
    },

    lineLength () {
      if (!this.line) return

      const polygon = new google.maps.Polyline({ path: this.line.path })
      return Math.floor(google.maps.geometry.spherical.computeLength(polygon.getPath()))
    }
  }
}
</script>
