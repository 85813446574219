<template lang="html">
  <div class="range-slider" :class="[isDisabled, hasLabelClass]">
    <DragHelper
      :disabled="disabled"
      @dragstart="dragStart"
      @drag="drag"
      @dragend="dragEnd">
      <div ref="inner" class="range-slider-inner">
        <input
          class="range-slider-hidden"
          type="text"
          :name="name"
          :value="actualValue"
          :disabled="disabled" />

        <div class="range-slider-timeline">
          <slot name="timeline" />
        </div>

        <span ref="knob" class="range-slider-knob" :style="{ left: valuePercent + '%' }">
          <slot name="knob"></slot>
        </span>
      </div>
    </DragHelper>

    <div v-if="label" class="range-label">
      {{ label }}
    </div>
  </div>
</template>

<script>
import DragHelper from './dragHelper'
import { round } from './utils'

export default {
  components: {
    DragHelper
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number],
      default: 0
    },

    disabled: {
      type: Boolean,
      default: false
    },

    min: {
      type: [String, Number],
      default: 0
    },

    max: {
      type: [String, Number],
      default: 100
    },

    step: {
      type: [String, Number],
      default: 1
    },

    label: {
      type: [String, Number],
      default: null
    }
  },

  data () {
    return {
      actualValue: null,
      dragStartValue: null
    }
  },

  computed: {
    _min () {
      return Number(this.min)
    },

    _max () {
      return Number(this.max)
    },

    _step () {
      return Number(this.step)
    },

    valuePercent () {
      return (this.actualValue - this._min) / (this._max - this._min) * 100
    },

    hasLabelClass () {
      return this.label ? 'has-label' : ''
    },

    isDisabled () {
      return this.disabled ? 'is-dimmed is-disabled' : ''
    }
  },

  watch: {
    value (newValue) {
      const value = Number(newValue)

      if (newValue != null && !isNaN(value)) {
        this.actualValue = this.round(value)
      }
    },

    min () {
      this.actualValue = this.round(this.actualValue)
    },

    max () {
      this.actualValue = this.round(this.actualValue)
    }
  },

  created () {
    const { _min: min, _max: max } = this
    let defaultValue = Number(this.value)

    if (this.value == null || isNaN(defaultValue)) {
      if (min > max) {
        defaultValue = min
      } else {
        defaultValue = (min + max) / 2
      }
    }

    this.actualValue = this.round(defaultValue)
  },

  methods: {
    dragStart (event, offset) {
      this.dragStartValue = this.actualValue

      if (event.target === this.$refs.knob) {
        return
      }

      // If the click is out of knob, move it to mouse position
      this.drag(event, offset)
    },

    drag (event, offset) {
      const { offsetWidth } = this.$refs.inner
      this.actualValue = this.round(this.valueFromBounds(offset.left, offsetWidth))
      this.emitInput(this.actualValue)
    },

    dragEnd (event, offset) {
      const { offsetWidth } = this.$refs.inner
      this.actualValue = this.round(this.valueFromBounds(offset.left, offsetWidth))

      if (this.dragStartValue !== this.actualValue) {
        this.emitChange(this.actualValue)
      }
    },

    emitInput (value) {
      this.$emit('input', value)
    },

    emitChange (value) {
      this.$emit('change', value)
    },

    valueFromBounds (point, width) {
      return (point / width) * (this._max - this._min) + this._min
    },

    round (value) {
      return round(value, this._min, this._max, this._step)
    }
  }

}
</script>

<style lang="scss">
.range-slider {
  display: inline-flex;
  height: 0.75rem;
  width: 100%;

  &.disabled {
    opacity: 0.5;
  }

  .range-slider-inner {
    display: inline-block;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .range-slider-timeline {
    display: flex;
    position: absolute;
    left: 0;
    height: 0.75rem;
    border-radius: 0.75rem;
    width: 100%;
    overflow: hidden;
    background: #f2f2f2;
  }

  .range-slider-knob {
    background: #42555b;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    box-sizing: border-box;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  .range-slider-hidden {
    display: none;
  }
}
</style>
